header {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}
.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ========= CTA ========*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========= Header Socials ========*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ========= ME ========*/
/* .me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
} */
.me {
    /* background: linear-gradient(var(--color-primary), transparent); */
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 1rem;
    /* border-radius: 12rem 12rem 0 0; */
    /* overflow: hidden; */
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}
/* .me img{
    //background: linear-gradient(var(--color-primary), transparent); 
    width: 22rem;
    height: 34rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: -5rem;
    border-radius: 5rem 5rem 0 0;
    overflow: hidden;
    padding: -1.6rem 1.5rem 1.5rem 1.5rem;
} */
.me img{
    /* background: linear-gradient(#f67542, transparent); */
    /* background: linear-gradient(180deg, rgba(234,190,60,1) 0%, rgba(252,94,146,1) 100%); */
    background: linear-gradient(146deg, rgba(235,191,61,1) 0%, rgba(251,93,108,1) 100%);
    /* background: linear-gradient(var(--color-primary), transparent); */
    /* width: 22rem;
    height: 34rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: -5rem;
    border-radius: 5rem 5rem 0 0;
    overflow: hidden;
    padding: -1.6rem 1.5rem 1.5rem 1.5rem; */
    margin-top: 3rem;
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    /* background-size: cover; */
    /* box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%); */
    order: 1;
    justify-self: center;
    width: 22.5rem;
    height: 22.5rem;
    animation: profile__animate 5s ease-in-out infinite 1s;

}
@keyframes profile__animate {
    0%  {
        border-radius: 60% 50% 30% 70%/60% 30% 70% 40%;
    }
    /* 25%  {
        border-radius: 50% 40% 20% 80%/70% 20% 60% 30%;
    } */
    50%  {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100%  {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

/* ========= Scroll Down ========*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =========MEDIA QUERIES(Medium Devices)========*/
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* =========MEDIA QUERIES(Small Devices)========*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__socials, .scroll__down {
        display: none;
    }
}